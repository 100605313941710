<template>
  <a-modal
  width="70%"
  :visible="value"
  :title="'选择'+ title"
  okText="确定"
  cancelText="取消"
  @cancel="handleCancel"
  @ok="handleOk">
    <!-- 图片 -->
    <imgPage v-model:value="obj.search" :dataSource="obj.dataSource" :pagination="pagination" @change="changePage" @changeCheck="changeCheck" @pressEnter="pressEnter" v-if="title == '图片'" ref="imgTemp"></imgPage>
    <!-- 音视频 -->
    <videoPage v-model:value="obj.search" :dataSource="obj.dataSource" :pagination="pagination" @change="changePage" @pressEnter="pressEnter" v-if="title == '音视频'" ref="videoTemp"></videoPage>
    <!-- 文档 -->
    <wordPage v-model:value="obj.search" :dataSource="obj.dataSource" :pagination="pagination" @change="changePage" @pressEnter="pressEnter" v-if="title == '文档'" ref="wordTemp"></wordPage>
    <!-- PPT -->
    <wordPage v-model:value="obj.search" :dataSource="obj.dataSource" :pagination="pagination" @change="changePage" @pressEnter="pressEnter" placeholder="请输入ppt的名字进行搜索" v-if="title == 'PPT'" ref="pptTemp"></wordPage>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive, watch, getCurrentInstance } from '@vue/runtime-core'
import imgPage from '@/views/soursePage/components/imgPage.vue'
import wordPage from '@/views/soursePage/components/wordPage.vue'
import videoPage from '@/views/soursePage/components/videoPage.vue'
import { getSourse } from '@/api/index'
import { SEND_TYPE_new } from '@/hooks/ali-oss'
import { message } from 'ant-design-vue'
export default defineComponent({
  props: {
    value: {},
    title: {
      type: String,
      defualt: '提示'
    }
  },
  components: { imgPage, wordPage, videoPage },
  setup (props, ctx) {
    const { proxy } = getCurrentInstance()
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0
    })
    const handleCancel = () => {
      ctx.emit('update:value', false)
    }
    const obj = reactive({
      search: '',
      dataSource: []
    })
    const wordObj = reactive({
      search: ''
    })
    const pptObj = reactive({
      search: ''
    })
    const handleSelectImg = () => {
      return obj.dataSource.filter(item => item.checked)
    }
    // 确定的回调
    const videoTemp = ref()
    const wordTemp = ref()
    const pptTemp = ref()
    const imgTemp = ref()
    const handleOk = () => {
      if (props.title === '图片') {
        console.log(handleSelectImg(), '获取的图片资源列表')
        if (imgTemp.value.checkList.length > SEND_TYPE_new[1].maxNum) {
          message.error(`最多可选择${SEND_TYPE_new[1].maxNum}张图片`)
          return false
        }
        proxy.$parent.resouseImgList = imgTemp.value.checkList
        ctx.emit('saveSubmit', imgTemp.value.checkList)
      }
      if (props.title === '音视频') {
        if (videoTemp.value.checkList.length > SEND_TYPE_new[2].maxNum) {
          message.error(`最多可选择${SEND_TYPE_new[2].maxNum}个音视频`)
          return false
        }
        proxy.$parent.resouseVideoList = videoTemp.value.checkList
        console.log(videoTemp.value.checkList, '获取的音视频资源列表')
        ctx.emit('saveSubmit', videoTemp.value.checkList)
      }
      if (props.title === '文档') {
        if (wordTemp.value.checkList.length > SEND_TYPE_new[4].maxNum) {
          message.error(`最多可选择${SEND_TYPE_new[4].maxNum}个文档`)
          return false
        }
        proxy.$parent.resouseFileList = wordTemp.value.checkList
        console.log(wordTemp.value.checkList, '获取的文档资源列表')
        ctx.emit('saveSubmit', wordTemp.value.checkList)
      }
      if (props.title === 'PPT') {
        if (pptTemp.value.checkList.length > SEND_TYPE_new[8].maxNum) {
          message.error(`最多可选择${SEND_TYPE_new[8].maxNum}个PPT`)
          return false
        }
        proxy.$parent.resousePPTList = pptTemp.value.checkList
        console.log(pptTemp.value.checkList, '获取的PPT资源列表')
        ctx.emit('saveSubmit', pptTemp.value.checkList)
      }
      ctx.emit('update:value', false)
    }
    let type = ''
    // 图片初始化选项
    const initImg = (data) => {
      const _ids = proxy.$parent.resouseImgList.map(item => item.id)
      return data.map(item => {
        if (_ids.includes(item.id)) {
          item.checked = true
        } else {
          item.checked = false
        }
        return item
      })
    }
    // 设置非图片类型的默认选中
    const initOther = (data, initData) => {
      const _ids = initData.map(item => item.id)
      return data.map(item => {
        if (_ids.includes(item.id)) {
          item.checked = true
        } else {
          item.checked = false
        }
        return item
      })
    }
    // 查询
    const fetchGetSourse = (ispage, callback) => {
      getSourse({
        type,
        search: obj.search,
        page: pagination.current,
        pagesize: pagination.pageSize
      }).then(res => {
        pagination.total = res.result
        callback && callback(res)
        if (props.title === '图片') {
          obj.dataSource = initImg(res.data)
        }
        if (ispage) {
          obj.dataSource = res.data
          return
        }
        if (props.title === '图片') {
          imgTemp.value.rowSelection.selectedRowKeys = proxy.$parent.resouseImgList.map(item => item.id)
          imgTemp.value.checkList = proxy.$parent.resouseImgList.length > 0 ? JSON.parse(JSON.stringify(proxy.$parent.resouseImgList)) : []
        }
        if (props.title === '音视频') {
          videoTemp.value.rowSelection.selectedRowKeys = proxy.$parent.resouseVideoList.map(item => item.id)
          videoTemp.value.checkList = proxy.$parent.resouseVideoList.length > 0 ? JSON.parse(JSON.stringify(proxy.$parent.resouseVideoList)) : []
        }
        if (props.title === '文档') {
          wordTemp.value.rowSelection.selectedRowKeys = proxy.$parent.resouseFileList.map(item => item.id)
          wordTemp.value.checkList = proxy.$parent.resouseFileList.length > 0 ? JSON.parse(JSON.stringify(proxy.$parent.resouseFileList)) : []
        }
        if (props.title === 'PPT') {
          pptTemp.value.rowSelection.selectedRowKeys = proxy.$parent.resousePPTList.map(item => item.id)
          pptTemp.value.checkList = proxy.$parent.resousePPTList.length > 0 ? JSON.parse(JSON.stringify(proxy.$parent.resousePPTList)) : []
        }
        obj.dataSource = res.data
      })
    }
    const changePage = (page) => {
      pagination.current = page
      fetchGetSourse(true)
    }
    const changeCheck = (checked, index) => {
      obj.dataSource[index].checked = checked
      console.log('我被触发的次数')
    }
    watch(() => props.value, (newVal) => {
      pagination.current = 1
      obj.search = ''
      if (newVal && props.title === '图片') {
        type = 'p'
        pagination.pageSize = 12
      }
      if (newVal && props.title === '音视频') {
        type = 'm'
        pagination.pageSize = 5
      }
      if (newVal && props.title === '文档') {
        type = 'w1,w3,w4'
        pagination.pageSize = 10
      }
      if (newVal && props.title === 'PPT') {
        type = 'w2'
        pagination.pageSize = 10
      }
      if (newVal) {
        fetchGetSourse()
      }
    })
    const utils = {
      debounceIdentify: 0,
      bounce (func, delay, ...args) {
        this.debounceIdentify && clearTimeout(this.debounceIdentify)
        this.debounceIdentify = setTimeout(() => {
          func.apply(this, args)
        }, delay || 300)
      }
    }
    const pressEnter = () => {
      pagination.current = 1
      utils.bounce(fetchGetSourse, 0)
    }
    return {
      handleCancel,
      obj,
      wordObj,
      pptObj,
      handleOk,
      imgTemp,
      videoTemp,
      wordTemp,
      pptTemp,
      pagination,
      changePage,
      changeCheck,
      pressEnter
    }
  }
})
</script>
<style lang="scss" scoped>
</style>
