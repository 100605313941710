
import { message, Empty } from 'ant-design-vue'
import { getTagList, postEnc, encAb, encResourse, bindEncDetail, editEnc, sourse } from '@/api/index'
import { computed, defineComponent, onMounted, reactive, ref, toRaw, watch } from 'vue'
import uploadAb from '@/views/encyclopediasManger/components/upload-ab.vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { SEND_TYPE_new, OSSURL, OSS_ICON } from '@/hooks/ali-oss'
import { filterKeys } from '@/hooks/common-hooks'
import { DeleteOutlined, PlusCircleOutlined, VerticalAlignTopOutlined } from '@ant-design/icons-vue'
import draggable from 'vuedraggable'
import { useRouter } from 'vue-router'
import addFileDialog from './addFileDialog.vue'
import videoDialog from '@/components/videoDialog.vue'
import radioDialog from '@/components/radioDialog.vue'
const newUpObj = {
  filter: '.mp4.mp3'
}
export interface FilesProps {
  file_name: string;
  file_path: string;
  [key: string]: any;
}
export interface FileProps {
  file?: FilesProps;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}
interface ReauestProps {
  type: string;
  file_name: string;
  file_path: string;
}
interface IdProps {
  courseware_id: number;
  encyclopedia_id: number;
}
export default defineComponent({
  props: ['id', 'isAdd', 'list', 'sourseDataAll', 'getSourse'],
  components: {
    uploadAb,
    DeleteOutlined,
    draggable,
    addFileDialog,
    videoDialog,
    radioDialog
  },
  setup (props, context) {
    const visibleVideo = ref(false)
    const visibleRadio = ref(false)
    const playUrl = ref()
    const visibleEnjoy = ref(false)
    const dialogTitle = ref()
    const addFile = (val: any) => {
      visibleEnjoy.value = true
      if (val.lable === '音频' || val.lable === '视频') {
        dialogTitle.value = '音视频'
      } else {
        dialogTitle.value = val.lable
      }
    }
    const router = useRouter()
    const resouseImgList: any = ref([])
    const changeResouseImg = (lists: never[]) => {
      resouseImgList.value = lists
    }

    const resouseVideoList: any = ref([])
    const changeResouseVideo = (lists: never[]) => {
      console.log(lists, 'resouseVideo')
      resouseVideoList.value = lists
    }

    const resouseFileList: any = ref([])
    const changeResouseFile = (lists: never[]) => {
      console.log(lists, 'resouseFilei')
      resouseFileList.value = lists
    }
    const resousePPTList: any = ref([])
    const changeResousePPT = (lists: never[]) => {
      console.log(lists, 'resouseFilePPT')
      resousePPTList.value = lists
    }
    let oldImgList: any[] = []
    let oldVideoList: any[] = []
    let oldWordist: any[] = []
    let oldPptList: any[] = []
    const checkTypeData = (oldArr: any, newArr: any) => {
      if (oldArr.length === 0 && newArr.value.length === 0) return false
      if (oldArr.length !== newArr.value.length) return true
      return oldArr.every((item: any, index: number) => {
        if (Object.prototype.hasOwnProperty.call(newArr.value[index], 'id')) {
          if (item.id === newArr.value[index].id) {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      })
    }
    const checkData = () => {
      // 更改了 返回 true
      if (checkTypeData(oldImgList, resouseImgList)) return true
      if (checkTypeData(oldVideoList, resouseVideoList)) return true
      if (checkTypeData(oldWordist, resouseFileList)) return true
      if (checkTypeData(oldPptList, resousePPTList)) return true
      return false
    }
    watch(() => props.list, (news) => {
      resouseImgList.value = news.filter((ele: any) => {
        return ele.type === 'p1'
      })
      console.log(resouseImgList.value)
      oldImgList = JSON.parse(JSON.stringify(resouseImgList.value))
      resouseVideoList.value = news.filter((ele: any) => {
        return ele.type === 'm4' || ele.type === 'm3'
      })
      oldVideoList = JSON.parse(JSON.stringify(resouseVideoList.value))
      resouseFileList.value = news.filter((ele: any) => {
        return ele.type.includes('w') && ele.type !== 'w2'
      })
      oldWordist = JSON.parse(JSON.stringify(resouseFileList.value))
      resousePPTList.value = news.filter((ele: any) => {
        return ele.type === 'w2'
      })
      oldPptList = JSON.parse(JSON.stringify(resousePPTList.value))
    }, {
      immediate: true
    })
    const getSourseIds = (data: any, val: any) => {
      return data.filter((item: any) => val.includes(item.type)).map((item: any) => item.id)
    }
    // 添加修改资源
    const sourseHttp = (parm: any) => {
      sourse({
        course_id: parseInt(props.sourseDataAll.course_id),
        ppt_ids: getSourseIds(parm, ['w2']).toString(),
        image_ids: getSourseIds(parm, ['p1']).toString(),
        video_ids: getSourseIds(parm, ['m3', 'm4']).toString(),
        wps_ids: getSourseIds(parm, ['w1', 'w3', 'w4']).toString()
      }).then(res => {
        props.getSourse()
        message.success('操作成功')
      })
    }
    const uploadReImg = ref()
    const uploadReVideo = ref()
    const uploadReFile = ref()
    // 添加资源
    const postEncResourse = (oldList: any, list: ReauestProps[]) => {
      encResourse({
        resources: list
      }).then(res => {
        sourseHttp(oldList.concat(res.data))
      })
    }
    // 重置list值
    const resetList = (lists: FileProps) => {
      return lists.map((ele: FileProps) => {
        return {
          type: ele.file_type ? ele.file_type : ele.type,
          file_name: ele.file_name ? ele.file_name : ele.name,
          file_path: ele.file_path ? ele.file_path : ele.file_path
        }
      })
    }
    // 提交表单
    const saveData = () => {
      const list = resouseImgList.value.concat(resouseVideoList.value, resouseFileList.value, resousePPTList.value)
      const oldList = list.filter((item: any) => {
        return item.id !== undefined
      })
      const newsList = list.filter((item: any) => {
        return item.id === undefined
      })
      if (newsList.length > 0) {
        postEncResourse(oldList, resetList(newsList))
      } else {
        sourseHttp(list)
      }
    }
    // 删除文件
    const deleteFile = (list: FileProps, index: number) => {
      list.splice(index, 1)
      console.log(list, '删除后')
      saveData()
    }
    const handleChange = (value: string[]) => {
      console.log(`selected ${value}`)
    }
    const getId = (arr: any) => {
      return arr.map((item: any) => item.id).toString()
    }
    const saveSubmit = (data: any) => {
      sourse({
        course_id: parseInt(props.sourseDataAll.course_id),
        ppt_ids: getId(resousePPTList.value),
        image_ids: getId(resouseImgList.value),
        video_ids: getId(resouseVideoList.value),
        wps_ids: getId(resouseFileList.value)
      }).then(res => {
        props.getSourse()
        message.success('操作成功')
      })
    }
    // 播放音频或者视频
    const hanldePlay = (record: any) => {
      if (record.type === 'm3') {
        visibleRadio.value = true
      } else {
        visibleVideo.value = true
      }
      playUrl.value = OSSURL + '/' + record.file_path
    }
    // 预览文档
    const hanldeReview = (record: any) => {
      if (record.type === 'w4') {
        // pdf跳转地址
        console.log('等待环境搭建')
        alert('等待环境搭建,先不管')
      } else {
        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${OSSURL}/${record.file_path}`)
      }
    }
    return {
      saveSubmit,
      resousePPTList,
      changeResousePPT,
      newUpObj,
      // resetSourseData,
      dialogTitle,
      addFile,
      visibleEnjoy,
      SEND_TYPE: SEND_TYPE_new,
      OSS_ICON,
      deleteFile,
      OSSURL,
      uploadReFile,
      uploadReVideo,
      uploadReImg,
      changeResouseFile,
      resouseFileList,
      changeResouseVideo,
      resouseVideoList,
      changeResouseImg,
      resouseImgList,
      ABactiveKey: ref('abw'),
      activeKey: ref('1'),
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      handleChange,
      saveData,
      checkData,
      visibleVideo,
      visibleRadio,
      playUrl,
      hanldePlay,
      hanldeReview
    }
  }
})
