<template>
  <div class="top-box">
      <span class="inline"></span>
      <span class="text">编辑课件</span>
  </div>
  <div class="box-shadow content">
    <a-tabs class="tabs" tab-position="left" v-model:activeKey="activeKey" @tabClick="changeTab">
      <a-tab-pane key="1">
        <template #tab>
          <div class="init-tab">
            <span>系统资源</span>
            <span class="is-checked" :class="{'is-active': systemData.isCheck}"></span>
          </div>
        </template>
        <div v-if="userInfo.school_id !== 1">
          <div class="title">
            <div>是否采用<a-switch class="ml-10" v-model:checked="systemData.isCheck" @change="changeCheck" /></div>
          </div>
        </div>
        <div class="list-box">
          <file-list-play ref="listRef" :sourseDataAll="sourseData" :isAdd="userInfo.school_id == 1" :list="fileList" :getSourse="getSourse"></file-list-play>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" v-if="userInfo.school_id != 1">
        <template #tab>
          <div class="init-tab">
            <span>校内资源</span>
            <span class="is-checked" :class="{'is-active': schoolData.isCheck}"></span>
          </div>
        </template>
        <div v-if="userInfo.role_id !== 71">
          <div class="title">
            <div>是否采用<a-switch class="ml-10" v-model:checked="schoolData.isCheck" @change="changeCheck" /></div>
          </div>
        </div>
        <div class="list-box">
          <file-list-play ref="listRef" :sourseDataAll="sourseData" :isAdd="userInfo.role_id == 71" :list="fileList" :getSourse="getSourse"></file-list-play>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" v-if="userInfo.school_id != 1 && userInfo.role_id > 71">
        <template #tab>
          <div class="init-tab">
            <span>个人资源</span>
          </div>
        </template>
         <div class="title">
          </div>
        <div class="list-box">
          <file-list-play ref="listRef" :sourseDataAll="sourseData" :isAdd="userInfo.school_id != 1 && userInfo.role_id > 71" :list="fileList" :getSourse="getSourse"></file-list-play>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>

</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from '@vue/runtime-core'
import fileListPlay from './components/listPlay.vue'
import { getInfo } from '@/api/cookie'
import { showConfirm } from '@/hooks/common-hooks'
import { bindCourseSourse, useSourse } from '@/api/index'
import { message, Empty } from 'ant-design-vue'
export default defineComponent({
  name: 'resourseEditor',
  props: ['id'], // 课程id
  components: { fileListPlay },
  setup (props) {
    const listRef = ref()
    const user = getInfo()
    const userInfo = JSON.parse(user)
    const sourseData = reactive({
      course_id: props.id,
      systemData: {
        isCheck: true,
        list: []
      },
      schoolData: {
        isCheck: true,
        list: []
      },
      personData: {
        isCheck: true,
        list: []
      }
    })
    const activeKey = ref('1')
    const fileList = ref([])
    const resetFileList = (activeKey) => {
      switch (activeKey) {
        case '1':
          fileList.value = sourseData.systemData.list
          break
        case '2':
          fileList.value = sourseData.schoolData.list
          break
        case '3':
          fileList.value = sourseData.personData.list
          break
        default:
          break
      }
    }
    const getSourse = () => {
      bindCourseSourse({ course_id: parseInt(props.id) }).then(res => {
        if (res.data) {
          sourseData.systemData.isCheck = res.data.check_system
          sourseData.systemData.list = res.data.system_explain ? res.data.system_explain : []
          sourseData.schoolData.isCheck = res.data.check_school
          sourseData.schoolData.list = res.data.school_explain ? res.data.school_explain : []
          sourseData.personData.isCheck = res.data.check_person
          sourseData.personData.list = res.data.person_explain ? res.data.person_explain : []
        }
        resetFileList(activeKey.value)
      })
    }
    const changeCheck = () => {
      console.log(123)
      // listRef.value.saveData()
      useSourse({
        course_id: parseInt(props.id),
        check_system: sourseData.systemData.isCheck,
        check_school: sourseData.schoolData.isCheck
      }).then(res => {
        console.log(res)
        message.success('操作成功')
        // getSourse()
      })
    }
    const setTabIndex = () => {
      if (userInfo.school_id !== 1) {
        activeKey.value = '2'
        return
      }
      if (userInfo.school_id !== 1 && userInfo.role_id > 71) {
        activeKey.value = '3'
      }
      // if (userInfo.school_id === 1) {
      //   activeKey.value = '1'
      // } else if (userInfo.role_id === 71) {
      //   activeKey.value = '2'
      // } else {
      //   activeKey.value = '3'
      // }
    }
    const changeTab = (activeKey) => {
      resetFileList(activeKey)
    }
    onMounted(() => {
      setTabIndex()
      getSourse()
    })
    return {
      fileList,
      changeTab,
      activeKey,
      changeCheck,
      listRef,
      userInfo,
      sourseData,
      getSourse,
      // sourseDataAll,
      ...toRefs(sourseData)
    }
  }
})
</script>

<style lang="scss" scoped>
.content{
  // height: calc(100% - 100px);
  .tabs{
    height: 100%;
  }
  .title{
    height: 45px;
    text-align: right;
    border-bottom: 1px solid #ddd;
    padding: 10px 24px;
    margin-left: -24px;
  }
}
::v-deep .ant-tabs .ant-tabs-left-content{
  padding:0;
}
.list-box{
  padding: 20px;
}
.init-tab{
  position: relative;
  display: flex;
  align-items: center;
  .is-checked{
    position: absolute;
    width: 0;
    height: 0;
    border-top: 30px solid #1890ff;
    border-right: 30px solid transparent;
    left: -24px;
    top: -7px;
    &::before{
      content: '未采';
      position: absolute;
      left: -4px;
      top: -30px;
      transform: rotate(-45deg) scale(.75);
      color: #fff;
    }
    &.is-active::before{
      content: '已采';
    }
  }
}
</style>
